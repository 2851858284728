import React from 'react';
import axios from 'axios';

import logo from '../logo.svg';
import w1 from './ba-pg1.jpg';
import w2 from './ba-pg2.jpg';
import port1 from '../Intersection 20.png';
import port2 from '../Intersection 15.png';
import port3 from '../Intersection 21.png';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import '../video-react.css';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { Player } from 'video-react';
import { NavLink } from 'react-router-dom';


function App() {

  
  return (
    <div className="App">
      <header className="brit-head App-header" id="home">
      <Container maxWidth="lg">
   
      
       
        </Container>
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
            <div className="port">
            <h2>BRITISH AIRWAYS<br/>
            <h4>HTML5 Rich-Media Banners, Javascript and CSS</h4></h2>
           
          
            </div>
          </Col>
        </Row>
      <Row>
   <Col  md={4} lg={4}>
  <div className="port">
  <h4>INSIGHT</h4>
<p>British Airways is a full service global airline, offering year-round low fares with an extensive global route network flying to and from centrally-located airports.
<br/>
The largest airline in the United Kingdom based on fleet size, or the second largest, behind easyJet, when measured by passengers carried. The airline is based in Waterside near its main hub at London Heathrow Airport.
</p>
<h4>ROLE</h4>
<p>In collaboration with the agency Schawk, we handled the digital production of British Airways for over a year.

Working on a series of campaigns over this period, on all forms of advertisements.
<br/>
we were able to successfully build Adaptive Mobile Banner ads, Desktop ads and Digital 6 sheets for outside displays composed of the same design elements that form part of their visual brand language.</p>
</div> 
</Col>
    <Col md={8} lg={8}>
    <div>
    <Player>
      <source src="https://www.fillmore-media.com/test/ba-pg3.mp4" />
    </Player>
    <br/>
    <br/>
    <Player>
      <source src="https://www.fillmore-media.com/test/ba-pg4.mp4" />
    </Player>
    <br/>
    <br/>
    <Carousel>
  <Carousel.Item>
  <img src={w1} className="port-pic" alt="" />
  </Carousel.Item>
  <Carousel.Item>
  <img src={w2} className="port-pic" alt="" />
  </Carousel.Item>
  
  </Carousel>
    </div>
</Col>
    
  </Row>
   </Container>
   
  

   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>MORE PROJECTS</h2>     
           
</div>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/lloyds">
  <span className="overlay"></span> 
  <img src={port1}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/lloyds">LLOYDS BANK</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/kia">
  <span className="overlay"></span> 
  <img src={port2}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/kia">KIA MOTORS</NavLink></h3>
  <span>UX/UI Interface design</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/tiffany">
  <span className="overlay"></span> 
  <img src={port3}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/tiffany">TIFFANY & CO</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
</Col>
    
  </Row>
   </Container>
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default App;
