import React from 'react';
import axios from 'axios';



import logo from '../logo.svg';
import w1 from './tiff-pg1.jpg';
import port1 from '../Intersection 14.png';
import port2 from '../Intersection 15.png';
import port3 from '../Intersection 16.png';


import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';
import { Player } from 'video-react';
import '../video-react.css';



function App() {

  
  return (
    <div className="App">
      <header className="tif-head App-header" id="home">
      <Container maxWidth="lg">
   
      
       
        </Container>
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
            <div className="port">
            <h2>TIFFANY & CO<br/>
            <h4>Digital Production - HTML5/Motion Creative Banners.</h4></h2>
           
          
            </div>
          </Col>
        </Row>
      <Row>
   <Col  md={4} lg={4}>
  <div className="port">
  <h4>INSIGHT</h4>
<p>Tiffany & Co. has been the world's premier jeweller and America's house of design
since 1837.<br/>

For more than 175 years, Tiffany & Co. has been synonymous with luxury and style. Few American companies have such an illustrious history.
</p>
<h4>ROLE</h4>
<p>In collaboration with the agency ICP Media, we handled the Online Banner production over a number of different creatives.

Working on two microsite/websites over this period.<br/>

We were able to successfully build digital banners using software such as Adobe Flash, Aftereffects for effects and editing Video.</p>
</div> 
</Col>
    <Col md={8} lg={8}>
    <div>
    <Player>
      <source src="https://www.fillmore-media.com/test/tiffany-pg3.mp4" />
    </Player>
    <br/>
    <br/>
    <img src={w1} className="port-pic" alt="" />
    </div>
</Col>
    
  </Row>
   </Container>
   
  

   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>MORE PROJECTS</h2>     
           
</div>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/lloyds">
  <span className="overlay"></span> 
  <img src={port1}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/lloyds">LLOYDS BANK</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/kia">
  <span className="overlay"></span> 
  <img src={port2}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/kia">KIA MOTORS</NavLink></h3>
  <span>UX/UI Interface design</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/britishairway">
  <span className="overlay"></span> 
  <img src={port3}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/britishairway">BRITISH AIRWAYS</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
</Col>
    
  </Row>
   </Container>
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default App;
