import React from 'react';
import logo from './logo.svg';
import w1 from './Intersection 27.png';
import w2 from './Intersection 28.png';
import w3 from './Intersection 29.png';
import w4 from './Intersection 30.png';
import w5 from './Intersection 31.png';
import w6 from './Intersection 32.png';
import w7 from './Intersection 33.png';
import w8 from './Intersection 34.png';




import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';



function App() {
  return (
    <div className="App">
      <header className="App-header ser-head" id="home">
      <div className="header-but">
      <Flip top>
      <h1>
        DIGITAL PRODUCTION HOUSE<br/>
AR/FRONT-END DEVELOPMENT.
        </h1>
        <NavLink to="/contact"><Button variant="light" className="example_f ">CONTACT US<span/></Button></NavLink>
      </Flip>
      </div>
      <iframe width="100%" scrolling="no" src="https://www.fillmore-media.com/test/index2.html" frameborder="0" title=""  className="header-frame"></iframe>
     
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
          <Fade bottom cascade>
            <div>
            <h4>We provide digital services and if required aid you in creative designs that capture the imagination, 
inspire people and deliver the desired results for clients.</h4>
            <h2 style={{ paddingTop:'0px'}}>SERVICES</h2>
            </div></Fade>
          </Col>
        </Row>
      <Row>
   <Col  md={6} lg={3}>
   <Fade bottom cascade>
  <div>
  <img src={w1} className="box-img" alt="" />
    <p style={{ textAlign:'left'}}>
  <b>AUGMENTED REALITY</b><br/>
  Filters for all platforms, Surface or target activated overlaying digital media onto the real world. 
Augmented Reality (AR) is a way of bringing digital content into the real world.
You have probably come across AR already without noticing it – Snapchat’s animated emojis, Instagram’s stickers and Pokémon Go are all mainstream examples.
</p></div> 
</Fade>
</Col>
    <Col md={6} lg={3}>
    <Fade bottom cascade>
    <div>
    <img src={w2} className="box-img" alt="" />
    <p style={{ textAlign:'left'}}><b>DESIGN</b><br/>
    We design and deliver the most innovative UX/UI web or Mobile advertising campaigns.
Offering minimalist UI/UX solutions that match trends
of today and technology
of tomorrow.
Creating a design that improves app stickiness and user retention is the recipe of successful user experience.
</p></div></Fade>
</Col>
<Col md={6} lg={3}>
<Fade bottom cascade>
<div>
<img src={w3} className="box-img" alt="" />
<p style={{ textAlign:'left'}}><b>DEVELOPMENT</b><br/>
Our industry experience delivers the most innovative online AR and 3D content
built for all platforms.
From prototyping an idea to launching the web portal with enterprise-grade features — we will take care of the entire web development for
your business.</p></div>
</Fade>
</Col>
<Col md={6} lg={3}>
<Fade bottom cascade>
<div>
<img src={w4} className="box-img" alt="" />
<p style={{ textAlign:'left'}}><b>PRODUCTION</b><br/>
Implementing the best online React, HMTL5 and Creative Media campaigns to the highest professional degree.
Applications built by us work flawlessly with devices like AR headsets, smart glasses, smart lenses, tablets, smartphones and other augmented reality compatible devices. This eventually lets you reach a larger audience.</p>
</div>
</Fade>
</Col>
    
  </Row>
   </Container>
   <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
          <Fade bottom>
            <div>
           
            <h2>PROCESS</h2>
            </div>
            </Fade>
          </Col>
        </Row>
      <Row>
   <Col  md={6} lg={3}>
   <Fade bottom cascade>
  <div>
  <img src={w5} className="box-img" alt="" />
    <p style={{ textAlign:'left'}}>
  <b>CREATIVE CONSULTATION</b><br/>
  All our projects start with a call or meeting to discuss what it is you want to create, and what you hope it will achieve. 
  Because AR/Front End is still a new technology, there are often misconceptions about what it can and can’t do, and so our role at this point is to be clear and honest about your idea, whilst offering suggestions on how the project might work.</p></div> 
  </Fade>
  </Col>
    <Col md={6} lg={3}>
    <Fade bottom cascade>
    <div>
    <img src={w6} className="box-img" alt="" />
    <p style={{ textAlign:'left'}}><b>SCOPING &amp; PROTOYPING</b><br/>
    Then we’ll get to work on a product scope – a detailed description of what it is we’re creating, the exact functionality it will have, and the associated timelines & costs.
    Once this has been agreed, we start building the first iteration of your product.</p></div>
    </Fade>
</Col>
<Col md={6} lg={3}>
<Fade bottom cascade>
<div>
<img src={w7} className="box-img" alt="" />
<p style={{ textAlign:'left'}}><b>REVIEW &amp; AMENDMENTS</b><br/>
Our industry experience delivers the most innovative online AR and 3At regular intervals throughout the prototyping process, we will send you builds of the product to review and feedback on. 
This way, any issues are picked up quickly and changes made. Obviously, it depends on the size of the project, but most AR/Front End projects take between 4-6 weeks.
</p></div>
</Fade>
</Col>
<Col md={6} lg={3}>
<Fade bottom cascade>
<div>
<img src={w8} className="box-img" alt="" />
<p style={{ textAlign:'left'}}><b>INTERACTIVE DELIVERY</b><br/>
Whether we are developing an immersive high-end Augmented Reality project on the latest iOS or Android device our work is always delivered in iterative stages. Each project will be captured in a statement of work, providing our clients with a clearly defined, plain English, description of all aspects of the work.
Our clients are always aware of progression and happy with the work being created.</p>
</div>
</Fade>
</Col>
    
  </Row>
   </Container>
   
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
          <Fade bottom >
            <div>
            <h2>PRICING</h2>
            </div>
            </Fade>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4}>
     <div className="plan">
  <div className="plan-top">
    
    <p><b>MICROSITES/APPS</b></p>

  </div>
  <div class="plan-text">
  <p style={{ textAlign:'left'}}><b>OPTION 1</b><br/>
Basic 4-page Build<br/> 
PWA site + Hosting<br/>
<b>OPTION 2</b><br/> 
Basic 4-page Build<br/> 
WordPress Site + Hosting<br/>
<b>3 REVISIONS</b><br/> 
+ £50 hourly rate there after<br/>
Source File<br/>
Commercial Use<br/>
Delivery 4-6 Weeks <br/>

</p>
  </div>
  <NavLink to="/contact"><Button variant="light"  className="example_f price-but" style={{ marginTop:'40px'}} >£1000 + VAT<span/></Button></NavLink>
  </div>
   </Col>
    <Col md={4} lg={4} >
    <div className="plan">
  <div className="plan-top">
    
    <p><b>AUGMENTED REALITY</b></p>

  </div>
  <div class="plan-text">
  <p style={{ textAlign:'left'}}><b>OPTION 1</b><br/>
  3 AR Filters<br/> 
- 1 Platform £1000 + VAT<br/> 
3-4-week Delivery<br/>
<b>OPTION 2</b><br/> 
1 AR Filter<br/> 
- 3 Platforms £500 + VAT<br/>  
2-3-week Delivery<br/>   
<b>3 REVISIONS</b><br/> 
+ £50 hourly rate there after<br/>
Source File<br/>
Commercial Use<br/>
Delivery 1-2 Weeks <br/>

</p>
  </div>
 
  <NavLink to="/contact"><Button variant="light"  className="example_f price-but" style={{ marginTop:'40px'}} >PRICE ABOVE<span/></Button></NavLink>

  </div>
</Col>
<Col md={4} lg={4} >
<div className="plan">
  <div className="plan-top">
    
    <p><b>CUSTOM PROJECTS</b></p>

  </div>
  <div class="plan-text">
  <p style={{ textAlign:'left'}}><b>OPTION</b><br/>
We can make what ever<br/>  
you desire.<br/>  
Contact us for Consultation.<br/> 


</p>
  </div>
  <NavLink to="/contact"><Button variant="light" className="example_f price-but" style={{ marginTop:'40px'}} >CONTACT US<span/></Button></NavLink>

  </div>
</Col>
    
  </Row>
  <Row>
   <Col md={4} lg={4}>
     <div className="plan">
  <div className="plan-top">
    
    <p><b>BANNERS</b></p>

  </div>
  <div class="plan-text">
  <p style={{ textAlign:'left'}}><b>OPTION 1</b><br/>
  4 HTML5 Banners<br/> 
– 1 Creative<br/>
<b>OPTION 2</b><br/> 
4 Social Banners<br/> 
– 1 Creative<br/>
<b>3 REVISIONS</b><br/> 
+ £50 hourly rate there after<br/>
Source File<br/>
Commercial Use<br/>
Delivery 1-2 Weeks <br/>

</p>
  </div>
  <NavLink to="/contact"><Button variant="light"  className="example_f price-but" style={{ marginTop:'40px'}} >£350 + VAT<span/></Button></NavLink>
  </div>
   </Col>
    <Col md={4} lg={4} >
    <div className="plan">
  <div className="plan-top">
    
    <p><b>EMAIL</b></p>

  </div>
  <div class="plan-text">
  <p style={{ textAlign:'left'}}><b>OPTION</b><br/>
  Responsive Build<br/> 
– 1 Design/Test<br/>
£50 – £50 hourly design rate<br/>
<b>3 REVISIONS</b><br/> 
+ £50 hourly rate there after<br/>
Source File<br/>
Commercial Use<br/>
Delivery 1-2 Weeks <br/>

</p>
  </div>
  <NavLink to="/contact"><Button variant="light"  className="example_f price-but" style={{ marginTop:'40px'}} >£250 + VAT<span/></Button></NavLink>

  </div>
</Col>
<Col md={4} lg={4} >
<div className="plan">
  <div className="plan-top">
    
    <p><b>VIDEO</b></p>

  </div>
  <div class="plan-text">
  <p style={{ textAlign:'left'}}><b>OPTION</b><br/>
  5 - 10 min branding videos<br/> 

<b>3 REVISIONS</b><br/> 
+ £50 hourly rate there after<br/>
Source File<br/>
Commercial Use<br/>
Delivery 2-3 Weeks <br/>

</p>
  </div>
  <NavLink to="/contact"><Button variant="light"  className="example_f price-but" style={{ marginTop:'40px'}} >£500 + VAT<span/></Button></NavLink>
</div>
</Col>
    
  </Row>
  
   </Container>
   
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default App;
