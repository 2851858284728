import React from 'react';
import logo from './logo.svg';
import B1 from './calvin.svg';
import B2 from './ford.svg';
import B3 from './ibm.svg';
import B4 from './psvita.svg';
import B5 from './skysports.svg';
import B6 from './unilever.svg';
import B7 from './hublot.svg';
import B8 from './warner.svg';

import port1 from './Intersection 14.png';
import port2 from './Intersection 15.png';
import port3 from './Intersection 16.png';
import port4 from './Intersection 17.png';
import port5 from './Intersection 18.png';
import port6 from './Intersection 19.png';
import port7 from './Intersection 20.png';
import port8 from './Intersection 21.png';
import port9 from './Intersection 22.png';



import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';


const home = () => {
  return (
    <div className="App">
      
      <header className="App-header work-head" id="home">
      <div className="header-but">
      <Flip top>
      <h1>
        DIGITAL PRODUCTION HOUSE<br/>
AR/FRONT-END DEVELOPMENT.
        </h1>
        <NavLink to="/contact"><Button variant="light" className="example_f ">CONTACT US<span/></Button></NavLink>
      </Flip>
      </div>
      <iframe width="100%" scrolling="no" src="https://www.fillmore-media.com/test/index3.html" frameborder="0" title=""  className="header-frame"></iframe>
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
          <Fade bottom >
            <div>
            <h4>We focus on working closely with Agencies and Brands to help them grow their online business, 
by providing a consistent production resource.<br/>
Here is a glimpse of some Augmented Reality and Web Applications.</h4>
            </div>
            </Fade>
          </Col>
        </Row>
   
   </Container>
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
          <Fade bottom>
            <div>
            <h2>LATEST PROJECTS</h2>
            </div>
            </Fade>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4} className="folio">
     <div className="grid-item-holder">
  <div className="box-item">
    <NavLink to="/bluecoat">
  <span className="overlay"></span> 
  <img src={port1}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3> <NavLink to="/bluecoat">SYMANTEC BLUECOAT</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/kia">
  <span className="overlay"></span> 
  <img src={port2}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/kia">KIA MOTORS</NavLink></h3>
  <span>UX/UI Interface design</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/fitbit">
  <span className="overlay"></span> 
  <img src={port3}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/fitbit">FITBIT</NavLink></h3>
  <span>Interface Design/Development</span>
  </div></div>
</Col>
    
  </Row>
  <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/hennym">
  <span className="overlay"></span> 
  <img src={port4}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3> <NavLink to="/hennym">HENNY MAESTRO</NavLink></h3>
  <span>Brand Development</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/britishairway">
  <span className="overlay"></span> 
  <img src={port5}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/britishairway">BRITISH AIRWAYS</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/argos">
  <span className="overlay"></span> 
  <img src={port6}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/argos">ARGOS</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
</Col>
    
  </Row>
  <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/lloyds">
  <span className="overlay"></span> 
  <img src={port7}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/lloyds">LLOYDS BANK</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/tiffany">
  <span className="overlay"></span> 
  <img src={port8}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/tiffany">TIFFANY & CO</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/hdmovie">
  <span className="overlay"></span> 
  <img src={port9}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/hdmovie">HD MOIVE LIBARY</NavLink></h3>
  <span>React Api Microsite</span>
  </div></div>
</Col>
    
  </Row>
        <Row>
          <Col sm={12}>
            <div>
            <h2>TESTIMONIAL</h2>
            </div>
          </Col>
        </Row>
  <div className="">
       <Carousel>
       <Carousel.Item>
       <div className="test-box">
    <h4>Fillmore Media whilst working at Vivid, They particularly excelled in Interactive advertising and website design, he also has good all-round design skills and a great attitude to work and clients.
    <br/><b>-MICHAEL GAMMON@Vivid Associates</b></h4>
   </div>
  </Carousel.Item>
  <Carousel.Item>
  <div className="test-box">
   
    <h4>Fillmore Media has worked for me in both a technical and creative capacity. They have always impressed and as a result has always been asked back! Ontop of his skillset, Henrys dedication, enthusiasm and accuracy of work are what really stands out.
    <br/><b>-Nick Pounder@Bonobo</b></h4>
   
    </div>
  </Carousel.Item>
  <Carousel.Item>
  <div className="test-box">
    <h4>Fillmore Media worked with us for two weeks to complete a digital project which he did in an efficient and professional manner. They kept to the deadlines and solved problems along the way. I'd have him back with no hesitation.
    <br/><b>-Peter Young, Director@LMC Design</b></h4>
        </div>
  </Carousel.Item>
  <Carousel.Item>
  <div className="test-box">
    <h4>Fillmore Media pays attention to detail, goes out of his way to deliver on time and is a good communicator. We always enjoy working with him.
    <br/><b>-Nicholas Caruana@Theframeworks</b></h4>
     </div>
  </Carousel.Item>
    
  </Carousel>
    </div>

  <Button variant="light" href="/services" className="example_f" style={{ marginTop:'40px'}} >CHECKOUT OUR PACKAGES<span/></Button>
   </Container>
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>SIGN UP FOR OUR NEWSLETTER</h2>     
            <div className="news"> <iframe width="100%" className="newsbox" scrolling="no" src="https://landing.mailerlite.com/webforms/landing/l4f4e2" frameborder="0" title=""></iframe>
</div>
</div>
          </Col>
        </Row>
   </Container>
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>BRANDS WE'VE ALSO WORKED FOR</h2>
            </div>
          </Col>
        </Row>
      <Row>
   <Col sm={3}>
  <div>
  <img src={B1} className="skill3" alt="" />
 
  </div>
   </Col>
    <Col sm={3}>
    <div>
    <img src={B2} className="skill3" alt="" />
    

    </div>
</Col>
<Col sm={3}>
<div>
<img src={B3} className="skill3" alt="" />

</div>
</Col>
<Col sm={3}>
<div>
<img src={B4} className="skill3" alt="" />

</div>
</Col>
    
  </Row>
  <Row>
   <Col sm={3}>
  <div>
  <img src={B5} className="skill3" alt="" />
 
  </div>
   </Col>
    <Col sm={3}>
    <div>
    <img src={B6} className="skill3" alt="" />
    
    </div>
</Col>
<Col sm={3}>
<div>
<img src={B7} className="skill3" alt="" />

</div>
</Col>
<Col sm={3}>
<div>
<img src={B8} className="skill3" alt="" />

</div>
</Col>
    
  </Row>
   </Container>
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default home;
