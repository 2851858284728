import React from 'react';
import logo from './logo.svg';
import lens from './len-studio.png';
import spark from './spark-ar-studio.png';
import kit from './kit-ar-studio.png';
import arcore from './ARCore-logo.png';
import html5 from './html5.png';
import react from './react.png';
import java from './javascript.png';
import blend from './blender_icon.png';
import w1 from './Intersection 27.png';
import w2 from './Intersection 28.png';
import w3 from './Intersection 29.png';
import w4 from './Intersection 30.png';
import p1 from './Intersection 31.png';
import p2 from './Intersection 32.png';
import p3 from './Intersection 33.png';
import p4 from './Intersection 34.png';

import port1 from './Intersection 14.png';
import port2 from './Intersection 15.png';
import port3 from './Intersection 16.png';
import port4 from './Intersection 17.png';
import port5 from './Intersection 18.png';
import port6 from './Intersection 19.png';
import port7 from './Intersection 20.png';
import port8 from './Intersection 21.png';
import port9 from './Intersection 22.png';



import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';

const home = () => {
  
  return (
    <div className="App">
      
      <header className="App-header" id="home">
       

      <div className="header-but">
      <Flip top>
      <h1>
        DIGITAL PRODUCTION HOUSE<br/>
AR/FRONT-END DEVELOPMENT.
        </h1>
        <NavLink to="/work"><Button variant="light" className="example_f ">EXPLORE OUR WORK<span/></Button></NavLink>
      </Flip>
      </div>

      <iframe width="100%" scrolling="no" src="https://www.fillmore-media.com/test/index.html" frameborder="0" title=""  className="header-frame"></iframe>
     
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
          <Fade bottom cascade>
            <div>
            <h4>We focus on delivering beautifully executed digital, 3D content with the very latest engaging technology linked
across all leading platforms to brands, small and medium sized businesses.<br/> 
Over 11 years commercial experience now at your service.</h4>
            <h2>WHAT WE DO</h2>
            </div>
            </Fade>
          </Col>
        </Row>
      <Row>
        
   <Col  md={6} lg={3}>
   <Fade bottom cascade>
  <div>
  <img src={w1} className="box-img" alt="" />
    <p>
  <b>AUGMENTED REALITY</b><br/>
Filters for all platforms, Surface or target activated overlaying digital media
onto the real world.</p></div>
</Fade> </Col>
    <Col md={6} lg={3}>
   <Fade bottom cascade>

    <div>
    <img src={w2} className="box-img" alt="" />
    <p><b>DESIGN</b><br/>
    We design and deliver the most innovative UX/UI web or Mobile advertising campaigns.
</p></div></Fade>
</Col>
<Col md={6} lg={3}>
<Fade bottom cascade>

<div>
<img src={w3} className="box-img" alt="" />
<p><b>DEVELOPMENT</b><br/>
Our industry experience delivers the most innovative online AR and 3D content
built for all platforms.</p></div></Fade>
</Col>
<Col md={6} lg={3}>
<Fade bottom cascade>

<div>
<img src={w4} className="box-img" alt="" />
<p><b>PRODUCTION</b><br/>
Implementing the best online React, HMTL5 and Creative Media campaigns to the highest professional degree.</p>
</div></Fade>
</Col>
    
  </Row>
   </Container>
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
          <Fade bottom>

            <div>
            <h2>TECH WE USE</h2>
            </div></Fade>
          </Col>
        </Row>
      <Row>
   <Col sm={3}>
   <Fade bottom>
  <div>
  <img src={lens} className="skill" alt="" />
  <p>LENS STUDIO</p>
  </div>
  </Fade>
   </Col>
    <Col sm={3}>
    <Fade bottom>
    <div>
    <img src={spark} className="skill" alt="" />
    <p>SPARK AR</p>

    </div></Fade>
</Col>
<Col sm={3}>
<Fade bottom>
<div>
<img src={kit} className="skill" alt="" />
<p>AR KIT</p>
</div></Fade>
</Col>
<Col sm={3}>
<Fade bottom>
<div>
<img src={arcore} className="skill" alt="" />
<p>AR CORE</p>
</div></Fade>
</Col>
    
  </Row>
  <Row>
   <Col sm={3}>
   <Fade bottom>
  <div>
  <img src={html5} className="skill" alt="" />
  <p>HTML5</p>
  </div>
  </Fade>
   </Col>
    <Col sm={3}>
    <Fade bottom>
    <div>
    <img src={react} className="skill" alt="" />
    <p>REACT</p>
    </div>
    </Fade>
</Col>
<Col sm={3}>
<Fade bottom>
<div>
<img src={java} className="skill" alt="" />
<p>JAVASCRIPT</p>
</div>
</Fade>
</Col>
<Col sm={3}>
<Fade bottom>
<div>
<img src={blend} className="skill2" alt="" />
<p>BLENDER</p>
</div>
</Fade>
</Col>
    
  </Row>
   </Container>
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
          <Fade bottom>
            <div>
            <h2>LATEST PROJECTS</h2>
            </div>
            </Fade>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4} className="folio">
     <div className="grid-item-holder">
  <div className="box-item">
    <NavLink to="/bluecoat">
  <span className="overlay"></span> 
  <img src={port1}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3> <NavLink to="/bluecoat">SYMANTEC BLUECOAT</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/kia">
  <span className="overlay"></span> 
  <img src={port2}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/kia">KIA MOTORS</NavLink></h3>
  <span>UX/UI Interface design</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/fitbit">
  <span className="overlay"></span> 
  <img src={port3}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/fitbit">FITBIT</NavLink></h3>
  <span>Interface Design/Development</span>
  </div></div>
</Col>
    
  </Row>
  <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/hennym">
  <span className="overlay"></span> 
  <img src={port4}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3> <NavLink to="/hennym">HENNY MAESTRO</NavLink></h3>
  <span>Brand Development</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/britishairway">
  <span className="overlay"></span> 
  <img src={port5}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/britishairway">BRITISH AIRWAYS</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/argos">
  <span className="overlay"></span> 
  <img src={port6}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/argos">ARGOS</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
</Col>
    
  </Row>
  <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/lloyds">
  <span className="overlay"></span> 
  <img src={port7}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/lloyds">LLOYDS BANK</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/tiffany">
  <span className="overlay"></span> 
  <img src={port8}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/tiffany">TIFFANY & CO</NavLink></h3>
  <span>Display Advertisement</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/hdmovie">
  <span className="overlay"></span> 
  <img src={port9}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/hdmovie">HD MOIVE LIBARY</NavLink></h3>
  <span>React Api Microsite</span>
  </div></div>
</Col>
    
  </Row>
  <NavLink to="/services"><Button variant="light" className="example_f" style={{ marginTop:'40px'}} >CHECKOUT OUR PACKAGES<span/></Button></NavLink>
   </Container>
   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>SIGN UP FOR OUR NEWSLETTER</h2>     
            <div className="ml-form-embed news"
  data-account="509885:c9k4y7w8a6"
  data-form="2077408:l4f4e2">
</div>
</div>
          </Col>
        </Row>
   </Container>
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default home;
