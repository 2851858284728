import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Home from './comp/home';
import Services from './comp/services';
import Work from './comp/work';
import Contact from './comp/contact';
import Error from './comp/error';
import Api from './comp/api';
import Blue from './comp/folio/bluecoat';
import Kia from './comp/folio/kia';
import Fit from './comp/folio/fitbit';
import Henny from './comp/folio/hennym';
import Brit from './comp/folio/britishairway';
import Argos from './comp/folio/argos';
import Lloyds from './comp/folio/lloyds';
import Tiff from './comp/folio/tiffany';
import HD from './comp/folio/hdmovie';
import Navigation from './comp/navigation';
 
class App extends Component {
  state = {
    contacts: []
  }
  render() {
    return (      
       <BrowserRouter>
        <div>
          <Navigation />
            <Switch>
             <Route path="/" component={Home} exact/>
             <Route path="/services" component={Services}/>
             <Route path="/work" component={Work}/>
             <Route path="/contact" component={Contact}/>
             <Route path="/bluecoat" component={Blue}/>
             <Route path="/kia" component={Kia}/>
             <Route path="/fitbit" component={Fit}/>
             <Route path="/hennym" component={Henny}/>
             <Route path="/britishairway" component={Brit}/>
             <Route path="/argos" component={Argos}/>
             <Route path="/lloyds" component={Lloyds}/>
             <Route path="/tiffany" component={Tiff}/>
             <Route path="/hdmovie" component={HD}/>
             <Route path="/api" component={Api}/>
            <Route component={Error}/>
           </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default App;