import React from 'react';

import logo from '../logo.svg';
import w1 from './hd-pg1.jpg';
import w2 from './hd-pg2.jpg';
import port1 from '../Intersection 14.png';
import port2 from '../Intersection 19.png';
import port3 from '../Intersection 16.png';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';




function App() {

  
  return (
    <div className="App">
      <header className="hd-head App-header" id="home">
      <Container maxWidth="lg">
   
      
       
        </Container>
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
            <div className="port">
            <h2>HD MOVIE LIBARY<br/>
            <h4>REACT API Microsite, Front-end development CSS, React and Javascript</h4></h2>
           
          
            </div>
          </Col>
        </Row>
      <Row>
   <Col  md={4} lg={4}>
  <div className="port">
  <h4>INSIGHT</h4>
<p>One of the WEB's leading Movie Api, offering more than 60,000 title online. 
</p>
<h4>ROLE</h4>
<p>In-house Developement, we handled the front-end development for the Platform.
Working on the Main website over this period.<br/>

we were able to successfully build responsive store pages using HTML5, jQuery and REACT structured from the design elements that form part of their visual brand language.</p>

</div> 

</Col>
    <Col md={8} lg={8}>
    <a class="port-cta" href="http://www.henryapaw.com/port/hd-movies/index.html" target="_blank" rel="noopener noreferrer"><Button variant="light"  className="example_f" style={{ marginBottom:'30px'}} >VISIT SITE<span/></Button></a>
    <Carousel>
  <Carousel.Item>
  <img src={w1} className="port-pic" alt="" />
  </Carousel.Item>
  <Carousel.Item>
  <img src={w2} className="port-pic" alt="" />
  </Carousel.Item>
 
  </Carousel>
 
</Col>
    
  </Row>
  
   </Container>
   
  

   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>MORE PROJECTS</h2>     
           
</div>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
    <NavLink to="/bluecoat">
  <span className="overlay"></span> 
  <img src={port1}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3> <NavLink to="/bluecoat">SYMANTEC BLUECOAT</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/argos">
  <span className="overlay"></span> 
  <img src={port2}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/argos">ARGOS</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/fitbit">
  <span className="overlay"></span> 
  <img src={port3}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/fitbit">FITBIT</NavLink></h3>
  <span>Interface Design/Development</span>
  </div></div>
</Col>
    
  </Row>
   </Container>
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default App;
