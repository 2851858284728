import React from 'react';
import axios from 'axios';

import logo from '../logo.svg';
import w1 from './blue-pg1.png';
import w2 from './blue-pg2.png';
import port1 from '../Intersection 19.png';
import port2 from '../Intersection 15.png';
import port3 from '../Intersection 16.png';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import '../App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';




function App() {

  
  return (
    <div className="App">
      <header className="blue-head App-header" id="home">
      <Container maxWidth="lg">
   
      
       
        </Container>
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
            <div className="port">
            <h2>SYMANTEC BLUE COAT<br/>
            <h4>HTML5 Microsite, Front-end development CSS, Jquery and Javascript</h4></h2>
           
          
            </div>
          </Col>
        </Row>
      <Row>
   <Col  md={4} lg={4}>
  <div className="port">
  <h4>INSIGHT</h4>
<p>Blue Coat Systems provides hardware, software, and services designed for cybersecurity and network management.
<br/>
Bought for $4.65 billion on June 16 by Symantec the largest acquisition Symantec has made in a decade and would make the combined company the largest enterprise security business globally.
</p>
<h4>ROLE</h4>
<p>In collaboration with the agency April6, we handled the front-end development for Symantec + Bluecoat.
<br/>
Working on two microsite/websites over this period.

we were able to successfully build responsive microsites using HTML5, jQuery and CSS structured of the brand design elements that form part of their visual brand language.</p>
</div> 
</Col>
    <Col md={8} lg={8}>
    <a class="port-cta" href="https://www.henryapaw.com/port/bluecoat-map/index.html" target="_blank" rel="noopener noreferrer"><Button variant="light"  className="example_f" style={{ marginBottom:'30px'}} >VISIT SITE<span/></Button></a>
    <Carousel>
  <Carousel.Item>
  <img src={w1} className="port-pic" alt="" />
  </Carousel.Item>
  <Carousel.Item>
  <img src={w2} className="port-pic" alt="" />
  </Carousel.Item>
 
    
  </Carousel>
</Col>
    
  </Row>
   </Container>
   
  

   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>MORE PROJECTS</h2>     
           
</div>
          </Col>
        </Row>
        <Row>
   <Col md={4} lg={4} className="folio">
   <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/argos">
  <span className="overlay"></span> 
  <img src={port1}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/argos">ARGOS</NavLink></h3>
  <span>Front-End Development</span>
  </div></div>
   </Col>
    <Col md={4} lg={4} className="folio">
    <div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/kia">
  <span className="overlay"></span> 
  <img src={port2}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/kia">KIA MOTORS</NavLink></h3>
  <span>UX/UI Interface design</span>
  </div></div>
</Col>
<Col md={4} lg={4} className="folio">
<div className="grid-item-holder">
  <div className="box-item">
  <NavLink to="/fitbit">
  <span className="overlay"></span> 
  <img src={port3}  alt="" />
  </NavLink>
  </div>
  <div class="grid-item">
  <h3><NavLink to="/fitbit">FITBIT</NavLink></h3>
  <span>Interface Design/Development</span>
  </div></div>
</Col>
    
  </Row>
   </Container>
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default App;
