import React from 'react';
import axios from 'axios';

import logo from './logo.svg';
import w2 from './Intersection 35.png';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import Flip from 'react-reveal/Flip';
import Fade from 'react-reveal/Fade';
import { NavLink } from 'react-router-dom';




function App() {
  const { register, handleSubmit, reset, errors } = useForm();
  const onSubmit = (data, e) => {
    axios({
      method: "POST", 
      url:"https://fillmore-media-server.herokuapp.com/send", 
      data: data,
    }).then((response)=>{
      if (response.data.status === 'success'){ 
        alert("Message Sent."); 
      }else if(response.data.status === 'fail'){
        alert("Message failed to send.")
      }
    })
    e.target.reset();
  };
  
  return (
    <div className="App">
      <header className="App-header con-head" id="home">
      <div className="header-but">
      <Flip top>
      <h1>
        DIGITAL PRODUCTION HOUSE<br/>
AR/FRONT-END DEVELOPMENT.
        </h1>
        <NavLink to="/contact"><Button variant="light" className="example_f ">CONTACT US<span/></Button></NavLink>
      </Flip>
      </div>
      <iframe width="100%" scrolling="no" src="https://www.fillmore-media.com/test/index4.html" frameborder="0" title=""  className="header-frame"></iframe>
      </header>
      <Container maxWidth="lg">
        <Row>
          <Col sm={12}>
          <Fade bottom >
            <div>
            <h2 style={{paddingBottom:'0px'}}>CONTACT US</h2>
            <h4>Tell us about your next project. Give us a call. Drop us an email. Connect socially</h4>
          
            </div></Fade>
          </Col>
        </Row>
      <Row>
   <Col  md={6} lg={6}>
  <div>
  <form onSubmit={handleSubmit(onSubmit)} className="contact-sty">
  <input name="Name" placeholder="Name:" id="Name" ref={register({ required: true, maxLength: 30 })} /><br/>
  {errors.Name && "Full name is required"}<br/>
      <input name="Email" placeholder="Email:" id="Email" ref={register({ required: true, })} /><br/>
      {errors.Email && "Email is required"}<br/>
      <input name="Telno" placeholder="Tel No:" id="Telno" ref={register({ required: true, maxLength: 20 })} /><br/>
  {errors.Telno && "Telephone Number is required"}<br/>
      <input name="CompName" placeholder="Company Name:"  id="CompName" ref={register({ required: true, })} /><br/>
      {errors.CompName && "Company name is required"}<br/>
      <select name="Budget" id="Budget"  ref={register({ required: true})}>
      <option value="">Select Budget</option>
        <option value="0-500">£0-£500</option>
        <option value="500-5000">£500 - £5000</option>
        <option value="5000 +">£5000 +</option>
      </select><br/>
      {errors.Budget && "Budget is required"}<br/>
      <select name="Service" id="Service" ref={register({ required: true})}>
      <option value="">Selected Service</option>
        <option value="Apps">Apps</option>
        <option value="Filters">AR Filters</option>
        <option value="3D">3D Modelling</option>
        <option value="Banners">Banners</option>
      </select><br/>
      {errors.Service && "Picking a service is required"}<br/>
      <br/>

      <textarea name="Message" placeholder="Message:" id="Message" rows="4" ref={register({  maxLength: 60 })} /><br/>
  <br/>
      <input type="submit" Value="SEND" className="but"/>
    </form>
</div> 
</Col>
    <Col md={6} lg={6}>
    <div>
    <img src={w2} className="" alt="" />
    </div>
</Col>
    
  </Row>
   </Container>
   
  

   <Container maxWidth="lg">
   <Row>
          <Col sm={12}>
            <div>
            <h2>SIGN UP FOR OUR NEWSLETTER</h2>     
            <div className="news"> <iframe width="100%" scrolling="no"  className="newsbox" src="https://landing.mailerlite.com/webforms/landing/l4f4e2" frameborder="0" title="news1"></iframe>
</div>
</div>
          </Col>
        </Row>
   </Container>
   
   <div style={{ width: '96%' }} className="footer">
   <Row>
   <Col sm={3}>
   <img src={logo} className="foot-logo" style={{height: '9vmin', marginLeft:'15px'}} alt="logo" />
   
   
   
   </Col>
    <Col sm={5}>
<p>If you have any further enquiries about Fillmore-media.com.</p>
<p>
<b>CALL ME</b><br/>
+44 07904707062<br/>
<b>EMAIL ME</b><br/>
info@fillmore-media.com<br/>
<b>STUDIO ADDRESS</b><br/>
Studio 281, Wimbledon Arts Studio, 10 Riverside Rd, London SW17 0BB
</p>

</Col>
    <Col sm={4}>
<p><b>FOLLOW ME ON:</b></p>

 <a href="https://twitter.com/fillmoremedia"  ><TwitterIcon className="icon"/></a> <a href="https://www.linkedin.com/in/henryapaw" ><LinkedInIcon className="icon"/></a>
<br/>
<br/>
<p>WE’RE LOOKING FORWARD TO HEARING FROM YOU!</p>
    </Col>
  </Row>
  <Row>
   <Col sm={3}>
   <Button variant="outline-light" href="#home" className="top"><b><i class="fas fa-angle-up"></i></b></Button>
   </Col>
    <Col sm={9}>

<p  style={{ color: '#DAB800', fontSize:'11px' }}>©FILLMORE-MEDIA.COM. ALL COPYRIGHT SOLELY BELONGS TO FILLMORE-MEDIA LTD. DESIGN WITH FEELING...</p>

</Col>
    
  </Row>

   </div>
    </div>
  );
}

export default App;
