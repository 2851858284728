import React from 'react';
import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
 
const Navigation = () => {
    return (
       <div>
           <Container  className="Menu">
          <Navbar expand="lg" className="Nav">
<Navbar.Brand href="#home"><img src={logo} className="Main-logo" alt="logo" /></Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse id="basic-navbar-nav">
<Nav className="mr-auto">
  <NavLink to="/" className="nav-link">HOME</NavLink>
  <NavLink to="/services" className="nav-link">WHAT WE DO</NavLink>
  <NavLink to="/work" className="nav-link">WORK</NavLink>
  <NavLink to="/contact" className="nav-link">CONTACT</NavLink>
</Nav>
</Navbar.Collapse>
</Navbar>
</Container>
       </div>
    );
}
 
export default Navigation;